import React, { Component } from "react";
import { Link } from "gatsby";
import FB from "../images/fb_icon.jpg";
import Twitter from "../images/twitter.jpg";
import Linkedin from "../images/linkedin.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      isBlogLoaded: false,
      blogContent: [],
      isAwardLoaded: false,
      awardContent: [],
      isCategoryLoaded: false,
      categoryContent: [],
      author_name: null,
      isvalid: false,
      search: "",
      isSuccess: false,
    };
  }

  FormSubmit = (props) => {
    if (this.state.isvalid == true) {
      this.setState({ isSuccess: false });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email_id: this.state.search }),
      };
      fetch("https://tatva.com/backendapi/api/v1/submission", requestOptions)
        .then((response) => response.json())
        .then(
          this.setState({ isSuccess: true }),
          setTimeout(
            () =>
              this.setState({
                isSuccess: false,
                search: "",
                type: 0,
                typeEnter: false,
              }),
            3000
          )
        );
    } else {
      this.setState({
        error: "Please enter valid email address.",
        isvalid: false,
      });
    }
  };

  onchange = (e) => {
    console.log(e.target.value);
    this.setState({ search: e.target.value });
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.target.value)) {
      //   isValid = false;
      this.setState({
        error: "Please enter valid email address.",
        isvalid: false,
      });
      console.log("Please enter valid email address.");
      //   errors["email"] = "Please enter valid email address.";
    } else {
      this.setState({ error: null, isvalid: true });
    }

    if (e.target.value.length == 0) {
      this.setState({ error: null, isvalid: false });
    }
  };

  clear = () => {
    this.setState({
      search: "",
      type: 0,
      typeEnter: false,
    });
  };

  render() {
    const { error, isSuccess, search, isvalid, disclaimer } = this.state;

    return (
      <React.Fragment>
        {JSON.stringify(disclaimer)}
        <div className="back-to-top">
          <Link to="#top"></Link>
        </div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="subscribe">
                  <h3> Follow Us on  </h3>
                  {/* <input name="" className="form-control" placeholder="Your email id" /> */}
                  {/* <input
                    className="form-control"
                    type="text"
                    placeholder="Your email id"
                    value={search}
                    onChange={this.onchange}
                  />
                  <input
                    type="submit"
                    className="form-btn"
                    onClick={this.FormSubmit}
                    disabled={isvalid == false ? true : false}
                    value=""
                  />
                  <div className="clearfix"></div> */}
                  {/* {error == null ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <p className="error"> {error} </p>
                    </React.Fragment>
                  )}

                  {isSuccess == false ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <p className="error">
                        {" "}
                        Thank you for your subscription.{" "}
                      </p>
                    </React.Fragment>
                  )} */}

                  <ul className="social_icon">
                    {/* <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/Tatva-Legal-Hyderabad-100632255609101/"
                      >
                        <img src={FB} />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/tatvalegalhyd">
                        <img src={Twitter} />
                      </a>
                    </li> */}
                    <li>
                      <a target="_blank" href="https://in.linkedin.com/company/tatva-legal-hyderabad">
                        <img src={Linkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="contact-address">
                  <h3>
                    <Link activeClassName="active" to={"/contact/"}>
                      {" "}
                      Contact Us
                    </Link>
                  </h3>
                  <h4> Telangana </h4>
                  <p>Tatva Legal, Hyderabad <br />
                    Tatva House, Plot No. 107A, <br />
                    Road No. 72, Jubilee Hills, <br />
                    Hyderabad - 500 110

                  </p>
                  <p className="differnt">
                    T: +91 - 40 2358 1000-04 <br />
                    E: hyderabad@tatvalegal.com
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="contact-address-andhra" style={{ paddingTop: "95px" }}>
                  <p>
                    Tatva Legal, Hyderabad <br />
                    Level 1, Orwell 2 <br />
                    Sattva Knowledge City, <br />
                    Gate No. 8, Raidurg, <br />
                    Hitec City, Hyderabad - 500 081

                  </p>
                  <p className="differnt">
                    T: +91 - 40 6740 9167 <br />
                    E hyderabad@tatvalegal.com
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-3">
                <div className="contact-address-andhra">
                  <h4> Andhra Pradesh </h4>
                  <p>
                    Tatva Legal, Vijayawada<br />
                    40-26, Srimanarayana Street,<br />
                    Sriram Nagar,<br />
                    Vijayawada - 520 010
                  </p>
                  <p className="differnt">
                    T: +91 - 91004 78818 <br />
                    E vijaywada@tatvalegal.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
